<template>
  <nav>
    <router-link to="/" id="logo">
      Dupla<span>e</span>m<span>.hu</span>
    </router-link>
    <font-awesome-icon
      :icon="['fas', 'bars']"
      size="xl"
      v-on:click="changeMenuVisibility()"
      class="menu-link"
    />
  </nav>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem;
}

#logo {
  font-weight: bold;
  color: $text-color;
  text-transform: uppercase;
  text-shadow: 0 0 0.5rem $text-color;
}

@keyframes glowing {
  0%,
  70%,
  75%,
  80%,
  82% {
    opacity: 1;
  }

  71%,
  74%,
  81% {
    opacity: 0.25;
  }
}

@media screen and (orientation: portrait), (max-width: 768px) {
  nav {
    background-color: $dark-color;
    z-index: 99;
  }

  .menu-link {
    color: $text-color;
  }
}

@media screen and (orientation: landscape) and (min-width: 768px) {
  nav {
    z-index: 98;
  }

  #logo {
    span {
      animation: glowing 10s ease-in-out 0s infinite normal both;
    }
  }

  .menu-link {
    color: $pop-color;
    transition: color 0.25s ease-in-out;

    &:hover {
      color: $text-color;
    }
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    changeMenuVisibility(): void {
      this.$store.commit("changeMenuVisibility");
    },
  },
});
</script>