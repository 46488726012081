import { createStore } from "vuex";

export default createStore({
  state: {
    isMenuVisible: {
      type: Boolean,
      value: false
    },
    isInfoModalVisible: {
      type: Boolean,
      value: false
    }
  },
  getters: {
    getIsMenuVisibleValue(state): boolean {
      return state.isMenuVisible.value;
    },
    getIsInfoModalVisibleValue(state): boolean {
      return state.isInfoModalVisible.value;
    }
  },
  mutations: {
    changeMenuVisibility(state): void {
      state.isMenuVisible.value = !state.isMenuVisible.value;
    },
    setMenuVisibilityToFalse(state): void {
      state.isMenuVisible.value = false;
    },
    setInfoModalVisibility(state, value): void {
      state.isInfoModalVisible.value = value;
    }
  },
  actions: {},
  modules: {},
});