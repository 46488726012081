import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import store from "@/store";
import AboutMeComponent from '@/views/AboutMeComponent.vue';
import ProjectsComponent from '@/views/ProjectsComponent.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: 'about-me',
  },
  {
    path: "/about-me",
    name: "about-me",
    component: AboutMeComponent
  },
  {
    path: "/projects",
    name: "projects",
    component: ProjectsComponent
  },
  /*
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  }
  */
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach(() => {
  store.commit("setMenuVisibilityToFalse");
});

export default router;