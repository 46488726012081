<template>
  <transition>
    <menu-component v-if="hasToShowMenu"></menu-component>
  </transition>
  <div class="container">
    <nav-component></nav-component>
    <div class="content-container">
      <main>
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component"></component>
          </transition>
        </router-view>
      </main>
      <footer>
        <span>
          duplaem.hu <font-awesome-icon :icon="['fas', 'copyright']" /> 2024
        </span>
      </footer>
    </div>
  </div>
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Quicksand&family=Raleway&display=swap");
@import "@/assets/styles/_variables.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: $primary-font;
  color: $text-color;
  scroll-behavior: smooth;
}

html,
body,
#app {
  height: 100%;
  width: 100%;
}

body {
  background-color: $main-color;
}

a {
  text-decoration: none;
  color: $text-color;
  width: fit-content;
}

p {
  margin-bottom: 1rem;
}

small {
  display: block;
  font-size: 0.75rem;
  margin-bottom: 0.75rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $secondary-font;
  margin-bottom: 1rem;
}

ol {
  margin: 1rem 0;

  li {
    margin-left: 2rem;
  }
}

a,
.menu-link,
.close-button,
.icon-link {
  cursor: pointer;
}

.title-icon {
  margin-right: 0.5rem;
}

.icon-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 1rem;
}

@mixin card-base {
  .card-header {
    margin-bottom: 1.25rem;
    border-bottom: 0.125rem solid $text-color;
  }
}

.card {
  @include card-base;
}

.close-button {
  position: relative;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  background-color: $pop-color;
  border: 0.25rem solid $dark-color;

  .close-icon {
    height: 1.5rem;
  }
}

/*
.blur-in {
  animation: blur-in 0.5s ease-in-out 0s 1 normal both;
}

@keyframes blur-in {
  from {
    filter: none;
  }
  to {
    filter: blur(0.125rem);
  }
}

.blur-out {
  animation: blur-out 0.5s ease-in-out 0s 1 normal both;
}

@keyframes blur-out {
  from {
    filter: blur(0.125rem);
  }
  to {
    filter: none;
  }
}
*/

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

::-webkit-scrollbar {
  width: $scrollbar-width;
  scrollbar-gutter: unset;
}

::-webkit-scrollbar-thumb {
  background-color: $pop-color;
}

// MEDIA QUERIES
@media screen and (orientation: portrait), (max-width: 768px) {
  .container {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    gap: 0;
  }

  .content-container {
    overflow: auto;
  }

  main {
    padding: 2.5rem;
  }

  .icon-link {
    color: $text-color;
  }

  footer {
    padding: 2.5rem;
    border-top: 0.125rem dashed $dark-color;
  }
}

@media screen and (orientation: landscape) and (min-width: 768px) {
  body {
    margin-right: $scrollbar-width;
  }

  main {
    width: 70%;
    margin: auto;
    padding: 2.5rem 0;
  }

  a:not(#logo):not(.router-link-active):not(.router-link-exact-active):not(
      .icon-link
    ) {
    border-bottom: 0.125rem transparent solid;
    transition: border 0.25s ease-in-out;

    &:hover {
      border-bottom: 0.125rem $text-color solid;
    }
  }

  .icon-link {
    color: $pop-color;
    transition: color 0.25s ease-in-out;

    &:hover {
      color: $text-color;
    }
  }

  @mixin card-base {
    background-image: linear-gradient(
      135deg,
      lighten($dark-color, 0.5%) 38%,
      $dark-color 42%
    );
    border-radius: 0.5rem;
    box-shadow: $my-box-shadow-smaller;
  }

  .card {
    @include card-base;

    padding: 2.5rem;
    border: 0.125rem solid $dark-color;
    transition: border 0.25s ease-in-out, transform 0.25s ease-in-out,
      box-shadow 0.25s ease-in-out;

    &:hover {
      border: 0.125rem solid $text-color;
      //transform: scale(101%);
      //box-shadow: $my-box-shadow;
    }
  }

  .close-button {
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;

    &:hover {
      background-color: $text-color;
      color: $pop-color;

      /*
      animation: pop 1s ease-in-out 0s 1;

      @keyframes pop {
        0%,
        50%,
        100% {
          transform: none;
        }
        20% {
          transform: scale(1.125);
        }
        60% {
          transform: scale(1.25);
        }
      }
      */
    }
  }

  footer {
    padding: 1.25rem;
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  methods: {},
  computed: {
    hasToShowLoadingScreen(): boolean {
      return this.$store.getters.getIsLoadingValue;
    },
    hasToShowMenu(): boolean {
      return this.$store.getters.getIsMenuVisibleValue;
    },
  },
});
</script>
