<template>
  <div class="menu">
    <div class="close-button" v-on:click="changeMenuVisibility">
      <font-awesome-icon :icon="['fas', 'xmark']" />
    </div>
    <ul>
      <li>
        <router-link to="/about-me" v-slot="{ isActive, isExactActive }">
          <font-awesome-icon
            :icon="['fas', 'angles-right']"
            v-if="isActive || isExactActive"
          />
          Bemutatkozás
        </router-link>
      </li>
      <li>
        <router-link to="/projects" v-slot="{ isActive, isExactActive }">
          <font-awesome-icon
            :icon="['fas', 'angles-right']"
            v-if="isActive || isExactActive"
          />
          Projektek
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.menu {
  background-color: $dark-color;
  box-shadow: $my-menu-box-shadow;

  li {
    list-style: none;
    margin-bottom: 0.5rem;
  }
}

.router-link-active,
.router-link-exact-active {
  color: $pop-color;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease-in-out;
}

.v-enter-to,
.v-leave-from {
  opacity: 1;
  transform: none;
}

@media screen and (orientation: portrait), (max-width: 768px) {
  .menu {
    position: fixed;
    left: 0;
    top: 3.5rem;
    width: 100%;
    padding: 1.25rem;
    z-index: 98;
  }

  .close-button {
    display: none;
  }

  .v-enter-from,
  .v-leave-to {
    transform: translateY(-20rem);
  }
}

@media screen and (orientation: landscape) and (min-width: 768px) {
  .menu {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    min-width: 20rem;
    padding: 2.5rem calc(2.5rem + $scrollbar-width) 2.5rem 2.5rem;
    z-index: 99;

    @for $i from 1 through 4 {
      li:nth-of-type(#{$i}) {
        animation: slide 0.5s ease-in-out $i * 0.25s 1 backwards;
      }

      @keyframes slide {
        0% {
          opacity: 0;
          transform: translateX(3rem);
        }
        100% {
          opacity: 1;
          transform: none;
        }
      }
    }
  }

  .close-button {
    top: -1.25rem;
    left: -4rem;
    border: 0.25rem solid $dark-color;
  }

  .v-enter-from,
  .v-leave-to {
    transform: translateX(20rem);
  }
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    changeMenuVisibility(): void {
      this.$store.commit("changeMenuVisibility");
    },
  },
});
</script>