<template>
  <div class="projects-container">
    <div class="card" onclick="window.open('https://favagaskomarom.hu', '_blank')">
      <div class="card-header">
        <h2>Szalai Favágás Komárom</h2>
      </div>
      <div class="card-body">
        <img src="@/assets/img/mockups/favagas_komarom_mockup.png" alt="Szalai Favágás Komárom" />
        <div>
          <h3>Leírás:</h3>
          <p>
            A Szalai Favágás Komárom csapatának készült egyszerű bemutatkozó
            oldal.
          </p>
          <h3>Felhasznált technológiák:</h3>
          <ul>
            <li>Vue 3</li>
            <li>Tailwind CSS</li>
            <li>Firebase</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.projects-container {
  .card {
    cursor: pointer;
  }

  .card-body {
    display: grid;
    gap: 1.25rem;

    img {
      width: 100%;
      filter: drop-shadow(0 0.5rem 0.125rem rgba(0, 0, 0, 0.125));
    }

    li {
      margin-left: 1rem;
    }
  }
}

@media screen and (orientation: landscape) and (min-width: 768px) {
  .projects-container {
    .card-body {
      grid-template-columns: 1fr 2fr;
      align-items: center;
    }
  }
}
</style>