import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40d59e16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "close-button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeMenuVisibility && _ctx.changeMenuVisibility(...args)))
    }, [
      _createVNode(_component_font_awesome_icon, { icon: ['fas', 'xmark'] })
    ]),
    _createElementVNode("ul", null, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/about-me" }, {
          default: _withCtx(({ isActive, isExactActive }) => [
            (isActive || isExactActive)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  icon: ['fas', 'angles-right']
                }))
              : _createCommentVNode("", true),
            _createTextVNode(" Bemutatkozás ")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/projects" }, {
          default: _withCtx(({ isActive, isExactActive }) => [
            (isActive || isExactActive)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  icon: ['fas', 'angles-right']
                }))
              : _createCommentVNode("", true),
            _createTextVNode(" Projektek ")
          ]),
          _: 1
        })
      ])
    ])
  ]))
}