import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f3b85a14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-box" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "progress-bar" }
const _hoisted_4 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "progress-value",
        style: _normalizeStyle(_ctx.getValueStyle)
      }, null, 4)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", null, _toDisplayString(_ctx.description), 1)
    ])
  ]))
}